import {
  FIXED_WIDTH,
  DEFAULT_ADDITIONAL_COVERAGE,
  DEFAULT_ROLL_WIDTH,
  INSTALLED_CARPET_OVERAGE_MULTIPLIER
} from '../constants';

export const getDefaultArea = ({ index, calcByArea, calculatorType, lineItemName }) => {

  return {
    key: `${index}`,
    calcByArea,
    name: lineItemName,
    length: '',
    width: '',
    depth: calculatorType === 'Volume' ? '3' : '',
    squareFootage: ''
  };
};

/*
  Don't touch this logic AT ALL without talking to the GCC team - this is a reworked duplicate of some of their API code
  and we need it to get the same calculation as their code. If there's something wrong with the formula, work with them
  to make sure it gets corrected in both places
*/
const findMinimumRollArea = (length, width, rollWidth) => {
  const findRollArea = (firstMeasurement, secondMeasurement, firstTime = true) => {
    // Determine whether to cut by width or length on first cut
    if (firstTime && firstMeasurement * 2 <= rollWidth && secondMeasurement <= rollWidth * 2) {
      return findRollArea(firstMeasurement * 2, secondMeasurement / 2, false);
    }

    if (firstMeasurement <= rollWidth) {
      return secondMeasurement * rollWidth;
    }

    // Determine left-over carpet
    const strips = Math.floor(firstMeasurement / rollWidth);
    const leftOverLength = firstMeasurement - (strips * rollWidth);
    const leftOverArea = leftOverLength > 0 ? findMinimumRollArea(leftOverLength, secondMeasurement, rollWidth) : 0.0;

    return (strips * secondMeasurement * rollWidth) + leftOverArea;
  };
  return Math.min(findRollArea(length, width), findRollArea(width, length));
};

// In GCC, they calculate stair steps in pairs of 3
// Each step is 6 sq. ft., so each pair of 3 is 18 sq. ft.
// Ex: 5 steps = 2 pairs of 3 = 36 sq. ft.
const calculateStairArea = (steps) => Math.ceil(steps / 3) * 18;

export const getCoverage = ({
  areas,
  calculatorType,
  info,
  isInstalledCarpet = false
}) => {
  let coverage = 0;
  const { publisher: rollWidth } = info?.pipCalculator || { publisher: DEFAULT_ROLL_WIDTH };

  areas.forEach((area) => {
    const {
      length, width, squareFootage, depth, calcByArea
    } = area;
    let areaValue;
    if (calculatorType === FIXED_WIDTH && length && width) {
      areaValue = findMinimumRollArea(length, width, rollWidth);
    } else { // Standard Formula
      areaValue = calcByArea ? Number(squareFootage) : length * width;
      if (calculatorType === 'Volume') {
        areaValue *= (depth / 12); // depth is in inches, so we must divide by 12
      }
    }
    coverage += isInstalledCarpet
      ? Math.round(areaValue * INSTALLED_CARPET_OVERAGE_MULTIPLIER)
      : areaValue;
  });

  return coverage;
};

export const getQuantity = ({
  addAdditionalCoverage = false,
  allowAdditionalCoverage = false,
  info,
  coverage,
  sizeValue
}) => {
  let quantity = 0;

  if (sizeValue) {
    let updateCoverage = coverage;
    if (allowAdditionalCoverage && addAdditionalCoverage) {
      const { additionalCoveragePercentage } = info?.pipCalculator || {};
      updateCoverage += (coverage * ((additionalCoveragePercentage || DEFAULT_ADDITIONAL_COVERAGE) / 100));
    }
    quantity = Math.ceil(updateCoverage / sizeValue);
  }
  return quantity;
};

export const checkBulkPrice = (quantity, pricing) => {
  const bulkPrice = pricing?.alternate?.bulk?.value;
  const bulkPriceThresholdQty = pricing?.alternate?.bulk?.thresholdQuantity;
  if (bulkPrice && bulkPriceThresholdQty && quantity >= bulkPriceThresholdQty) {
    return { price: bulkPrice, isBulkPrice: true };
  }

  const price = pricing?.value;
  return { price, isBulkPrice: false };
};

/* eslint-disable max-len */
export const disclaimer = 'These calculations are a rough estimate allowing for one coat of paint. They do NOT account for ceiling or trim. Precise paint needs will vary according to many factors, including application method.';
export const errorMessage = 'One or more fields have invalid input(s). Unable to calculate.';
/* eslint-enable max-len */