import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  ButtonGroup,
  DrawerBody,
  DrawerFooter,
  Alert
} from '@one-thd/sui-atomic-components';

export const SignInDrawer = ({ onClose }) => {

  const currentUrl = window.location.pathname;

  return (
    <>
      <DrawerBody>
        <Alert status="info">
          Please Sign In to Add to List
        </Alert>
      </DrawerBody>
      <DrawerFooter>
        <ButtonGroup orientation="vertical">
          <Button
            fullWidth
            variant="primary"
            href={`/auth/view/signin?redirect=${currentUrl}`}
          >
            Sign In
          </Button>
          <Button
            fullWidth
            variant="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
        </ButtonGroup>
      </DrawerFooter>
    </>
  );
};

SignInDrawer.propTypes = {
  onClose: PropTypes.func.isRequired
};

SignInDrawer.displayName = 'AddToListSignInDrawer';