import React, { createContext } from 'react';
import {
  arrayOf,
  node,
  oneOfType,
  shape
} from 'prop-types';

export const AddToListContext = createContext({});

export const AddToListProvider = ({ productData, children }) => {

  const productValue = productData?.pricing?.value;
  const identifiers = productData?.identifiers;
  const primaryImage = productData?.media?.images?.find(
    (img) => img.type === 'IMAGE' && img.subType === 'PRIMARY'
  );

  return (
    <AddToListContext.Provider value={{
      productValue,
      identifiers,
      primaryImage
    }}
    >
      {children}
    </AddToListContext.Provider>
  );
};

AddToListProvider.propTypes = {
  children: oneOfType([
    arrayOf(node),
    node
  ]).isRequired,
  productData: shape({}).isRequired
};