/* eslint-disable tailwindcss/no-arbitrary-value */
import React, { useContext } from 'react';
import { AddToListContext } from '../AddToListProvider';

export const ProductInfo = () => {
  const { identifiers, primaryImage } = useContext(AddToListContext);
  if ((!identifiers && !primaryImage)) {
    return null;
  }

  return (
    <div className="sui-flex sui-flex-row">
      <img
        src={primaryImage?.url.replace('<SIZE>', '145')}
        alt={identifiers?.productLabel}
        className="sui-aspect-square sui-max-w-[20%] sm:sui-max-w-[30%]"
        width="145px"
        height="145px"
      />
      <div className="sui-pl-6 sui-text-xl">
        <strong>{identifiers?.brandName}&nbsp;</strong>{identifiers?.productLabel}
      </div>
    </div>
  );
};

ProductInfo.displayName = 'ProductInfo';
