import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  extend, useDataModel, arrayOf, customType, params, string, shape
} from '@thd-nucleus/data-sources';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import {
  Drawer,
  DrawerHeader,
  Skeleton,
  SkeletonBlock,
  SkeletonLine
} from '@one-thd/sui-atomic-components';
import { useTheme } from '@thd-olt-component-react/theme-provider';
import THDStorageUtils, { COOKIE_STORAGE } from '@thd-olt-global/thd-storage-utils';
import { AddToListForm } from './AddToListForm';
import { CreateNewList } from './CreateNewList';
import { SignInDrawer } from './SignInDrawer';

const AddToListDrawer = ({
  itemId,
  open,
  onClose,
  quantity,
  products,
  skipListId,
  isSharedList,
  setFavoriteCount,
  favoriteCount,
  drawerLoadingHandler
}) => {
  let storeFrontSVOCID;
  let storeFrontUserID;

  const [showCreateList, setShowCreateList] = useState(false);
  const { svocID, userID, customerType, isLoggedIn } = useThdCustomer() || {};

  const theme = useTheme(AddToListDrawer);
  const { disableSignInDrawer } = theme.props;

  const storefrontCookieData = THDStorageUtils.get(COOKIE_STORAGE, { key: 'THD_STOREFRONT' });

  if (storefrontCookieData && storefrontCookieData !== 'undefined') {
    const storefrontPayload = storefrontCookieData.split('.')[1];
    /* istanbul ignore next */
    if (storefrontPayload) {
      const decodedStorefrontPayload = Buffer.from(storefrontPayload, 'base64').toString();
      const { SVOCID } = JSON.parse(decodedStorefrontPayload);
      storeFrontSVOCID = SVOCID;
      storeFrontUserID = (storeFrontSVOCID + '_STOREFRONT');
    }
  }

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('AddToListDrawer.ready');
  }, []);

  const { data, loading, refetch } = useDataModel('listDetails', {
    variables: {
      customerAccountID: storeFrontSVOCID || svocID,
      userId: storeFrontSVOCID ? storeFrontUserID : userID,
      customerType: storeFrontSVOCID ? null : customerType?.toUpperCase()
    },
    fetchPolicy: 'cache-first',
    skip: storeFrontSVOCID ? (!storeFrontSVOCID || !storeFrontUserID) : (!svocID || !userID),
    ssr: true
  });

  const { listDetails: listDetailsData = null } = data?.listDetails?.listResponse || {};
  const listDetails = (listDetailsData || []).filter((list) => list.listId !== skipListId);
  const listsExist = !!listDetails?.length;

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('AddToListDrawer.ready');
    drawerLoadingHandler(false);
  }, []);

  const onCreateList = () => {
    refetch();
  };

  const drawerContent = () => {
    if (!disableSignInDrawer && !isLoggedIn) {
      return (
        <SignInDrawer
          onClose={onClose}
        />
      );
    }

    return (
      (showCreateList || !listsExist) ? (
        <CreateNewList
          itemId={itemId}
          quantity={quantity}
          products={products}
          onClose={onClose}
          onCreate={onCreateList}
          isExistingLists={listsExist}
          isSharedList={isSharedList}
          setFavoriteCount={setFavoriteCount}
          favoriteCount={favoriteCount}
        />
      ) : (
        <AddToListForm
          itemId={itemId}
          quantity={quantity}
          products={products}
          listDetails={listDetails}
          shouldCreateList={setShowCreateList}
          onClose={onClose}
          isSharedList={isSharedList}
          setFavoriteCount={setFavoriteCount}
          favoriteCount={favoriteCount}
        />
      )
    );
  };

  return (
    <Drawer open={open} onClose={onClose}>
      <div
        // eslint-disable-next-line tailwindcss/no-arbitrary-value
        className="sui-flex sui-flex-col portrait:sui-h-[calc(88vh)] landscape:sui-h-screen sm:sui-h-screen"
        data-component="AddToListDrawer"
      >
        <DrawerHeader
          title="Add to List"
          onClose={onClose}
        />
        {
          loading
            ? (
              <Skeleton disablePadding="y" orientation="horizontal">
                <SkeletonBlock />
                <SkeletonLine />
              </Skeleton>
            )
            : (drawerContent())
        }
      </div>
    </Drawer>
  );
};

AddToListDrawer.propTypes = {
  itemId: PropTypes.string,
  skipListId: PropTypes.string,
  quantity: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isSharedList: PropTypes.bool.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number
  })),
  setFavoriteCount: PropTypes.func.isRequired,
  favoriteCount: PropTypes.number,
  drawerLoadingHandler: PropTypes.func.isRequired
};

AddToListDrawer.defaultProps = {
  itemId: null,
  skipListId: PropTypes.string,
  quantity: 1,
  products: [],
  favoriteCount: null,
};

AddToListDrawer.defaultThemeProps = {
  disableSignInDrawer: false
};

AddToListDrawer.displayName = 'AddToExistingList';

AddToListDrawer.dataModel = extend(
  {
    listDetails: params({
      customerAccountID: string().isRequired(),
      userId: string().isRequired(),
      customerType: customType('CustomerType').enum(['B2B', 'B2C'], 'B2C')
    }).shape({
      listResponse: shape({
        listDetails: arrayOf(shape({
          listId: string().isRequired(),
          listName: string()
        }))
      })
    })
  },
  CreateNewList,
  AddToListForm
);

export { AddToListDrawer };
