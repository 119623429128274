import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { useThdCustomer } from '@thd-olt-functional/customer-information';
import {
  useDataModel, params, string, shape, arrayOf
} from '@thd-nucleus/data-sources';
import { copy } from '@thd-olt-functional/utils';
import {
  Link,
  Typography,
  SkeletonLine
} from '@one-thd/sui-atomic-components';
import { EVENT_DIMENSION_NAME } from '../constants';

const imageContainer = classNames(
  'breadcrumbs__image-container',
  'sui-mr-1',
  'sui-h-4',
  'sui-w-4',
  'sui-bg-contain',
  'sui-bg-center',
  'sui-bg-no-repeat',
  'sui-inline-block',
  'sui-align-text-bottom'
);

const normalizeBreadcrumb = (crumb) => {
  return {
    id: null,
    name: null,
    label: null,
    refinementKey: null,
    dimensionName: 'Category',
    ...crumb
  };
};

const parseSearchModelForCrumbs = (crumbs = [], keyword, returnAll = false) => {
  if (keyword) {
    const textSearchCrumb = {
      url: '',
      label: 'Text Search'
    };

    let label;
    try {
      label = decodeURI(keyword);
    } catch (err) {
      label = keyword;
    }
    const keywordCrumb = {
      url: `/s/${keyword}?NCNI-5`,
      label
    };
    if (!crumbs.length) {
      crumbs.unshift(textSearchCrumb, keywordCrumb);
    } else {
      const textSearchCrumbIndex = crumbs.findIndex((crumb) => {
        return crumb.label === textSearchCrumb.label;
      });
      if (textSearchCrumbIndex === -1) {
        crumbs.unshift(textSearchCrumb, keywordCrumb);
      }
    }
  }
  if (crumbs.length > 0) {
    const hasCategory = crumbs.filter((crumb) => (crumb.dimensionName || '').toLowerCase() === 'category').length > 1;
    const breadcrumbs = hasCategory && !returnAll
      ? crumbs.filter((crumb) => (crumb.dimensionName || '').toLowerCase() === 'category')
      : crumbs.filter((crumb) => !crumb.deselectUrl);
    return breadcrumbs.map((crumb) => normalizeBreadcrumb(crumb));
  }
  return null;
};

const hasNoCategoryDimension = (breadcrumbs = []) => {
  return breadcrumbs.filter(
    (crumb) => (crumb.dimensionName || '').toLowerCase() === 'category'
  ).length === 0;
};

const filterEventBreadcrumbsForMobile = (breadcrumbs = [], shouldShowEventOnly) => {
  if (shouldShowEventOnly) {
    return breadcrumbs.filter((breadcrumb) => {
      return breadcrumb.dimensionName === EVENT_DIMENSION_NAME;
    });
  }
  return breadcrumbs.filter((breadcrumb) => {
    return breadcrumb.dimensionName !== EVENT_DIMENSION_NAME;
  });
};

const filterEmptyBreadcrumb = (breadcrumbs = []) => {
  return (breadcrumbs || []).filter((breadcrumb) => {
    return (breadcrumb.label || breadcrumb.name);
  });
};

const Breadcrumbs = (props) => {

  const { channel } = useContext(ExperienceContext);

  const [isBreadcrumbsExpanded, setIsBreadcrumbsExpanded] = useState(false);

  const {
    className,
    collectionId,
    shouldLastCrumbBeLinkableOverride,
    hideOnNoCategory,
    itemId,
    keyword,
    navParam,
    rounded,
    showInternet,
    shouldShowEventOnly,
    staticCrumbs,
    componentId,
    componentClass,
    componentPosition
  } = props;

  let crumbs;

  // eslint-disable-next-line
  const isControlledData = typeof props.data !== 'undefined' || typeof props.loading !== 'undefined' || staticCrumbs !== null;
  const isMobile = channel === 'mobile';

  const skipSearch = isControlledData || (!keyword && !navParam) || collectionId;

  const productResponse = useDataModel('product', {
    skip: !itemId || isControlledData,
    variables: {
      itemId
    }
  });

  const collectionResponse = useDataModel('collection', {
    skip: !collectionId,
    variables: {
      collectionId
    }
  });

  const opts = {
    skip: skipSearch,
    variables: { keyword, navParam }
  };
  const searchResponse = useDataModel('searchModel', opts);

  let data;
  let loading = false;
  // eslint-disable-next-line
  if (typeof props.data !== 'undefined') {
    ({ data } = props);
  } else if (itemId) {
    ({ data, loading } = productResponse);
  } else if (collectionId) {
    ({ data, loading } = collectionResponse);
  } else {
    ({ data, loading } = searchResponse);
  }

  useEffect(() => {
    if (crumbs) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('breadcrumbs.ready', { crumbs });
    }
  }, [data, crumbs]);

  if (loading && !data && !shouldShowEventOnly) {
    return (
      <div
        className="sui-grid sui-grid-cols-2 sm:sui-grid-cols-4 sui-gap-4 sui-w-full"
        data-component="BreadcrumbsPlaceholder"
      >
        {[...Array(isMobile ? 2 : 4)].map((i) => (
          <SkeletonLine
            key={i}
            variant="single"
          />
        ))}
      </div>
    );
  }
  if (!data && staticCrumbs === null) return null;
  let breadCrumbs = [];
  let searchCrumbs = null;

  if (data?.product?.taxonomy?.breadCrumbs) {
    breadCrumbs = copy(data?.product?.taxonomy?.breadCrumbs).data;
    breadCrumbs = isMobile ? filterEventBreadcrumbsForMobile(breadCrumbs, shouldShowEventOnly) : breadCrumbs;

  } else if (data?.searchModel?.taxonomy?.breadCrumbs) {
    breadCrumbs = copy(data?.searchModel?.taxonomy?.breadCrumbs).data;

  } else if (data?.collection?.taxonomy?.breadCrumbs) {
    breadCrumbs = copy(data?.collection?.taxonomy?.breadCrumbs).data;
  }

  if (hideOnNoCategory && hasNoCategoryDimension(breadCrumbs)) return null;

  if (itemId) {
    crumbs = (breadCrumbs || []).map((crumb) => {
      return normalizeBreadcrumb(crumb);
    });
    if (crumbs.length > 0) crumbs[crumbs.length - 1].shouldLastCrumbBeLinkable = shouldLastCrumbBeLinkableOverride;
  } else {
    const { keyword: keywordFromResponse } = data?.searchModel?.searchReport || {};
    crumbs = parseSearchModelForCrumbs(breadCrumbs, keywordFromResponse);
  }

  crumbs = filterEmptyBreadcrumb(crumbs);

  if (staticCrumbs) {
    crumbs = staticCrumbs.map((crumb) => normalizeBreadcrumb(crumb));
  }

  if (!crumbs || (crumbs && !crumbs.length)) {
    return null;
  }

  const crumbAttribute = crumbs[0].name ? 'name' : 'label';

  if (crumbs[0][crumbAttribute] !== 'Home') {
    const homePage = {
      url: '/'
    };
    homePage[crumbAttribute] = 'Home';
    crumbs.unshift(homePage);
    crumbs = crumbs.filter((crumb, idx) => {
      return !((crumb?.label || crumb?.name) === 'Home' && idx > 0);
    });

  }

  if (isMobile && shouldShowEventOnly) {
    crumbs.shift();
  }

  if (showInternet) {
    const internetNumber = {
      isInternetNumber: true,
      label: `Internet #${itemId}`,
    };
    crumbs.push(internetNumber);
  }

  const breadcrumbClasses = classNames(
    'sui-relative',
    'sui-z-1',
    'sui-overflow-x-auto',
    'sui-whitespace-nowrap',
    'sui-pr-2',
    '[&>*:nth-last-child(n+5)]:sui-hidden',
    'sui-pb-1', {
      'breadcrumbs__nowrap--rounded': rounded,
      'sui-pb-2': rounded,
      'sui-bg-primary': rounded,
      'sui--mt-1': rounded,
      'sm:sui-mt-0': rounded,
      'sui-rounded-t-none': rounded,
      'sui-rounded-b-md': rounded,
      'sm:sui-rounded-b-none': rounded,
      'sui-z-0': rounded,
      'sm:sui-z-auto': rounded,
      '[&>*:nth-last-child(n+3)]:sui-hidden': isMobile,
      '[&>*:nth-last-child(n+3)]:!sui-inline': isBreadcrumbsExpanded
    }
  );

  const expandBreadcrumbs = () => {
    setIsBreadcrumbsExpanded(true);
  };
  const limitList = isMobile ? crumbs.length > 2 : crumbs.length > 4;

  return (
    <div
      className="breadcrumbs sui-flex sui-flex-nowrap"
      data-component-id={componentId}
      data-component-name={componentClass}
      data-component-position={componentPosition}
      data-component="Breadcrumbs"
    >
      {limitList && (
        <button
          type="button"
          className={isBreadcrumbsExpanded ? 'sui-hidden' : null}
          onClick={expandBreadcrumbs}
        > &nbsp;...&nbsp;
        </button>
      )}
      <div className={breadcrumbClasses}>
        {crumbs.map((crumb, crumbIndex) => {
          let {
            dimensionName, isInternetNumber, creativeIconUrl, browseUrl, url
          } = crumb;
          const isLinkableCrumb = (crumbIndex !== crumbs.length - 1)
            || (crumbIndex === crumbs.length - 1 && crumbs[crumbs.length - 1].shouldLastCrumbBeLinkable);
          return (
            <div
              key={crumbIndex}
              className={classNames(
                'breadcrumb__item',
                'sui-inline',
                'sui-p-0',
                'sui-float-none',
                { 'sui-text-primary': isInternetNumber,
                  'sui-font-bold': dimensionName === EVENT_DIMENSION_NAME,
                  'breadcrumb__item--pipe': dimensionName === EVENT_DIMENSION_NAME }
              )}
            >
              {crumbIndex > 0 && !shouldShowEventOnly
                && <div aria-hidden="true" className="sui-inline sui-px-2">/</div>}
              {crumbIndex > 0 && shouldShowEventOnly
                && <div aria-hidden="true" className="sui-inline sui-px-2">|</div>}
              {isInternetNumber && (
                `Internet #${itemId}`
              )}
              {!isInternetNumber && creativeIconUrl && (
                <div
                  className={imageContainer}
                  style={{ backgroundImage: `url(${creativeIconUrl})` }}
                />
              )}
              {!isInternetNumber && isLinkableCrumb
                && <Link href={browseUrl || url} color="subtle">{crumb[crumbAttribute]}</Link>}
              {!isInternetNumber && !isLinkableCrumb
                && <Typography component="span" color="subtle">{crumb[crumbAttribute]}</Typography>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

Breadcrumbs.displayName = 'Breadcrumbs';

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  collectionId: PropTypes.string,
  shouldLastCrumbBeLinkableOverride: PropTypes.bool,
  hideOnNoCategory: PropTypes.bool,
  itemId: PropTypes.string,
  keyword: PropTypes.string,
  navParam: PropTypes.string,
  rounded: PropTypes.bool,
  showInternet: PropTypes.bool,
  data: PropTypes.shape({}),
  loading: PropTypes.bool,
  shouldShowEventOnly: PropTypes.bool,
  staticCrumbs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  })),
  componentId: PropTypes.string,
  componentClass: PropTypes.string,
  componentPosition: PropTypes.number
};

Breadcrumbs.defaultProps = {
  className: null,
  collectionId: null,
  shouldLastCrumbBeLinkableOverride: true,
  hideOnNoCategory: false,
  itemId: null,
  keyword: null,
  navParam: null,
  rounded: false,
  showInternet: false,
  data: undefined,
  loading: undefined,
  shouldShowEventOnly: false,
  staticCrumbs: null,
  componentId: '',
  componentClass: '',
  componentPosition: 1
};

const BreadCrumbType = shape({
  browseUrl: string(),
  creativeIconUrl: string(),
  deselectUrl: string(),
  dimensionName: string(),
  label: string(),
  refinementKey: string(),
  url: string()
});

Breadcrumbs.dataModel = {
  collection: params({
    collectionId: string().isRequired()
  }).shape({
    taxonomy: shape({
      breadCrumbs: arrayOf(shape({
        label: string(),
        url: string()
      }))
    })
  }),
  product: params({ itemId: string().isRequired() }).shape({
    itemId: string(),
    dataSources: string(),
    taxonomy: shape({
      breadCrumbs: arrayOf(BreadCrumbType)
    })
  }),
  searchModel: params({ keyword: string(), navParam: string() }).shape({
    taxonomy: shape({
      breadCrumbs: arrayOf(BreadCrumbType)
    }),
    searchReport: shape({
      keyword: string()
    })
  })
};

export { Breadcrumbs };
